import React from 'react';
import { Link } from 'gatsby';
import Image from 'reusecore/src/elements/Image';
import DocumentationSection from '../../../../../containers/SaasModern/Documentation';
import example_multiweek from '../../../../../../../common/src/assets/image/example_multiweek.png';
import template_options_multiweek from '../../../../../../../common/src/assets/image/template_options_multiweek.png';

const Documentation = () => {
  const content = (
    <div>
      <p>
        The Multi-Week template generates multiple weeks to one or more pages.
        It is similar to the default Month template, but can show between 1 and
        52 weeks instead of only whole months.
      </p>
      <p>
        <Image alt="" src={example_multiweek} />
      </p>
      <h2>Template Settings</h2>
      <p>
        The Multi-week template provides the following options when creating a
        calendar...
      </p>
      <Image alt="" src={template_options_multiweek} style={{ width: 448 }} />
      <table className="table">
        <tbody>
          <tr>
            <td>
              <strong>Start date</strong>
            </td>
            <td>
              The first date to show in the generated output. It should be
              consistent with the specified first day of week.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Number of weeks</strong>
            </td>
            <td>Between 1 and 52 weeks can be generated.</td>
          </tr>
          <tr>
            <td>
              <strong>First day of week</strong>
            </td>
            <td>The first day of the week will be the left-most day column.</td>
          </tr>
          <tr>
            <td>
              <strong>Min rows per day</strong>
            </td>
            <td>
              Each day cell will consist of the minimum number of specified
              rows. The higher the value, the more events will be able to fit,
              but larger values might it more difficult to fit the calendar on a
              single page when printing.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Max rows per day</strong>
            </td>
            <td>
              This is the maximum number of rows to be used for each day cell.
              If a date has more events than the maximum number of rows, an
              indication will be shown at the bottom of the day cell.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Text behavior</strong>
            </td>
            <td>
              Titles can be wrapped (if space allows), never wrapped, or shrunk
              to fit so that wrapping is unnecessary. You can pick the desired
              behavior via the drop-down list.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Item font size</strong>
            </td>
            <td>
              Override the default font size associated with each calendar data
              source.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Item sort order</strong>
            </td>
            <td>
              Sort items by either start time or calendar data source. When
              sorted by calendar data source, items will be effectively grouped
              based on their associated calendar data source.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Week header</strong>
            </td>
            <td>
              The content of the week header, shown to the left of each week
              row. This can be either the week date range or the week number.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Time Format</strong>
            </td>
            <td>
              The time format. Supported formats are <strong>12-hour</strong>{' '}
              (8:00 PM), <strong>24-hour</strong> (20:00),{' '}
              <strong>Shortened</strong> (8pm), <strong>Military </strong>
              (2000), and <strong>Numeric </strong>(20.0). An example time is
              shown for each format in the drop-down list.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Show start time</strong>
            </td>
            <td>
              When checked, event or task start times will be shown. The
              ordering of times, title, and location values can be specified in
              the <Link to="/Documentation/Options">PrintableCal options</Link>.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Show end time</strong>
            </td>
            <td>
              <span>When checked, event or task end times will be shown. </span>
            </td>
          </tr>
          <tr>
            <td>
              <strong>Use bold text for item times</strong>
            </td>
            <td>
              When checked, item titles will be in bold text, to help
              differentiate from the location and description values.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Show location</strong>
            </td>
            <td>When checked, event or task locations will be shown.</td>
          </tr>
          <tr>
            <td>
              <strong>Show descriptions within items</strong>
            </td>
            <td>
              When checked, descriptions will be shown within items if space is
              available.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Show descriptions in tooltips</strong>
            </td>
            <td>
              When checked, an item's description will be added to a comment
              that pops up when hovering the mouse over the item. Note, this
              only works when calendars are generated in Excel, not Word. Also,
              these comments won't be included in the printout.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Condense unused rows</strong>
            </td>
            <td>
              When this option is checked, any unused rows in day cells will be
              deleted, allowing the month to be dynamically sized so it can fit
              better when printing. Note, blank rows will not be removed if this
              would result in fewer rows than specified by the{' '}
              <strong>Min rows per day</strong> value.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );

  return (
    <DocumentationSection
      url="https://www.printablecal.com/Documentation/Create-Calendar/Templates-and-Options/Week-Multiweek"
      commentsId="commentsplus_post_171_489"
      title="Multi-Week Template"
      description="This is a multiweek template. It is similar to the default Month template, but can show between 1 and 52 weeks instead of only whole months."
      keywords="multiple week template, multi-week template, month template"
      content={content}
    />
  );
};

export default Documentation;
